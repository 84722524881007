import {GarudaApi} from "../../infrastructure/garudaApi";
import {Spinner} from "../spinner-module";
import {Localization} from "../localization-module";
import {PluralityEnum} from "../../models/enums/pluralityEnum";
import {MimeTypeEnum} from "../../models/enums/mimeTypeEnum";
import {FileDownloadModule} from "../file-download-module";
import {SubToolEnum} from "../../models/enums/subToolEnum";
import {ToolEnum} from "../../models/enums/toolEnum";
import {DropdownModule} from "ditmer-embla";
import {SelectedProfileModel} from "../../models/SelectedProfileModel";
import {StateStorage} from "../../infrastructure/stateStorage";
import {anonymous_setting_select_keyword, currentAttachedProfilesStorageKey} from "../../infrastructure/constants";
import {ReportTypeEnum} from "../../models/enums/reportTypeEnum";
import {CustomTooltipModule} from "../custom-tooltip-module";

export interface ReportModuleOptions {
    openButtonSelector: string;
    subtool: SubToolEnum;
    tool: ToolEnum;
    primaryProfileId?: string;
    outerCustomTooltipSelector?: string;
    onModalClose?: () => void;
}

export class ReportModule {
    private readonly garudaApi: GarudaApi;
    private readonly stateStorage: StateStorage;
    private readonly fileDownloadModule: FileDownloadModule;
    private isGroup: boolean

    private readonly reportModalSelector = $("#modal-report");
    private readonly modalReportSelector = "#modal-report-container";
    private readonly modalTitleSelector = $("#modal-report-header");
    private readonly modalResetSelector = this.reportModalSelector.find("#modal-reset");
    private readonly reportModalBody = $(this.modalReportSelector);
    private readonly generateReportStatus = $("#modal-generate-report-status");
    private readonly generateReportComplete = $("#modal-generate-report-complete");
    private readonly generatedReportTypeSelector = $("#generated-report-type");
    private readonly generatedReportNameSelector = $("#generated-report-name");
    private readonly reportHeaderInfoIconSelector = $("#modal-header-info-icon");
    private readonly reportActionSelector = "#report-action-button";
    private readonly reportLanguageSelector = "#report-language";
    private readonly reportVariantSelector = "#report-variant";
    private readonly spinnerController: Spinner;
    private readonly subtool : SubToolEnum;
    private readonly tool : ToolEnum;
    private btnReportModalBtn = "#btn-open-report-modal";
    private profileIds: string[];
    private plurality: PluralityEnum;
    private respondentName: string;
    private readonly attachedProfilesStorageKey: string;
    private readonly outerCustomTooltipSelector?: string;
    private readonly onModalClose?: () => void;
    private subtoolName: string;
    private selectedToolEnum: ToolEnum;
    private selectedSubToolEnum: SubToolEnum;
    private selectedReportType: ReportTypeEnum;

    constructor(options: ReportModuleOptions) {
        this.garudaApi = new GarudaApi();
        this.stateStorage = new StateStorage();
        this.fileDownloadModule = new FileDownloadModule();
        this.spinnerController = new Spinner("#modal-report-default-spinner", this.modalReportSelector);
        this.btnReportModalBtn = options.openButtonSelector;
        this.subtool = options.subtool;
        this.tool = options.tool;
        this.attachedProfilesStorageKey = `${currentAttachedProfilesStorageKey}${options.primaryProfileId}`;
        this.outerCustomTooltipSelector = options.outerCustomTooltipSelector;
        this.onModalClose = options.onModalClose;
    }

    public async initialize(profileIds: string[], plurality: PluralityEnum, manualModalOpen: boolean, respondentName?: string, subToolEnum?: SubToolEnum) {
        this.profileIds = profileIds;
        this.plurality = plurality;
        this.respondentName = respondentName;

        if (manualModalOpen) {
            await this.openModal(subToolEnum);
        } else {
            $(this.btnReportModalBtn).off("click").on("click", async (event) => {
                await this.openModal(subToolEnum);
            });
        }

        this.bindOnModalClose();
        this.initListenerForAllowReportGeneration();
    }

    public bindOnModalClose = () => {
        this.reportModalSelector.off('hidden.bs.modal').on('hidden.bs.modal', (e) => {
            $(e.currentTarget).find(".modal-body").empty();

            if(this.outerCustomTooltipSelector){
                this.initCustomTooltip(this.outerCustomTooltipSelector);
            }
            if(this.onModalClose) {
                this.onModalClose();
            }
        });
    }

    public async initializeSpecificReport(profileId: string, respondentName: string, reportType: ReportTypeEnum, reportName: string) {
        this.profileIds = [profileId];
        this.plurality = PluralityEnum.Single;
        this.respondentName = respondentName;

        this.reportModalBody.show();
        this.reportModalSelector.modal("show");
        await this.showReportDetails(this.profileIds, this.tool, this.subtool, reportType, reportName);
    }

    private initCustomTooltip = (tooltipSelector: string) => {
        const tooltipModule = new CustomTooltipModule({
            tooltipContainerIdSelector: tooltipSelector
        });
        tooltipModule.init();
    }

    private initListenerForAllowReportGeneration() {
        $(document).ready(function(){
            $("#modal-report-container").on("click", (e) => {
                const reportButton  = $("#modal-btn-report-request");

                const checkBox =  $(".report-checkbox-container").find(":input[type=checkbox]:checked")
                checkBox.length > 0 ?
                    reportButton.prop('disabled', false) :
                    reportButton.prop('disabled', true);
            })
        });
    }

    private async openModal(subToolEnum?: SubToolEnum): Promise<null> {
        this.initCustomTooltip("#custom-tooltip-container-report-modal");

        this.reportHeaderInfoIconSelector.hide();
        this.reportModalBody.show();
        switch (this.plurality) {
            case PluralityEnum.Single:
                await this.initializeContent(subToolEnum);
                break;
            case PluralityEnum.Multiple:
                await this.initializeMultipleContent();
                break;
            case PluralityEnum.Group:
                await this.initializeGroupContent();
                break;
            default:
                return null;
        }
    }

    private async initializeContent(subToolEnum?: SubToolEnum) {
        this.modalTitleSelector.text(Localization.getText("PersonalProfile_Report_Select_Type"));
        this.spinnerController.showSpinner();

        this.selectedSubToolEnum = subToolEnum;
        // Person profile IDs
        const personProfileIds = this.getProfileIdsFromStorage(SubToolEnum.Person);
        const jobProfileId = this.getProfileIdFromStorage(SubToolEnum.Job);
        const mirrorProfileId = this.getProfileIdFromStorage(SubToolEnum.Mirror);
        const referenceProfileId = this.getProfileIdFromStorage(SubToolEnum.Reference);

        // Focus person profile IDs
        const focusPersonProfileIds = this.getProfileIdsFromStorage(SubToolEnum.FocusPerson);
        const focusJobProfileId = this.getProfileIdFromStorage(SubToolEnum.FocusJob);
        const focusMirrorProfileId = this.getProfileIdFromStorage(SubToolEnum.FocusMirror);
        const focusDreamPositionProfileId = this.getProfileIdFromStorage(SubToolEnum.FocusDreamPosition);

        const subTool = subToolEnum !== null && subToolEnum !== undefined ? subToolEnum : this.subtool

        let reportModalBody;
        switch (subTool) {
            case SubToolEnum.Person:
                reportModalBody = await this.garudaApi.getPersonProfileReportTypes(jobProfileId, mirrorProfileId, referenceProfileId, personProfileIds);
                break;
            case SubToolEnum.FocusPerson:
                reportModalBody = await this.garudaApi.getFocusPersonReportTypes(focusJobProfileId, focusMirrorProfileId, focusDreamPositionProfileId, focusPersonProfileIds);
                break;
            case SubToolEnum.FocusReference:
                reportModalBody = await this.garudaApi.getFocusMirrorFollowupReportTypes();
                break;
            case SubToolEnum.JobVsJob:
                reportModalBody = await this.garudaApi.getJobProfilReportTypes(jobProfileId, mirrorProfileId, this.profileIds);
                break;
            case SubToolEnum.Job:
                reportModalBody = await this.garudaApi.getJobProfilReportTypes(jobProfileId, mirrorProfileId, this.profileIds);
                break;
            case SubToolEnum.FocusJob:
                reportModalBody = await this.garudaApi.getFocusJobProfilReportTypes(jobProfileId, mirrorProfileId, this.profileIds);
                break;
            default:
                reportModalBody = await this.garudaApi.getReportTypes(this.tool, subTool);
        }

        this.reportModalBody.html(reportModalBody);
        this.reportModalSelector.modal("show");
        this.initializeReportTypes();

        this.spinnerController.hideSpinner();
    }

    private getProfileIdFromStorage(subToolEnum: SubToolEnum): string {
        const appliedSelectedProfiles = this.stateStorage.load<SelectedProfileModel[]>(`${this.attachedProfilesStorageKey}`);
        if(appliedSelectedProfiles && appliedSelectedProfiles.length > 0){
            const selectedJobProfileModel = appliedSelectedProfiles.find(profile => profile.subtoolId === subToolEnum.toString());
            if(selectedJobProfileModel){
                return selectedJobProfileModel.profileId;
            }
        }

        return "";
    }

    private getProfileIdsFromStorage(subToolEnum: SubToolEnum): string[] {
        const appliedSelectedProfiles = this.stateStorage.load<SelectedProfileModel[]>(`${this.attachedProfilesStorageKey}`);
        if(appliedSelectedProfiles && appliedSelectedProfiles.length > 0){
            const selectedJobProfileModel = appliedSelectedProfiles.filter(profile => profile.subtoolId === subToolEnum.toString());
            if(selectedJobProfileModel){
                return selectedJobProfileModel.map(m => m.profileId);
            }
        }

        return [];
    }

    private async initializeMultipleContent() {
        this.modalTitleSelector.text(Localization.getText("PersonalProfile_IndividualReports"));
        this.spinnerController.showSpinner();

        this.reportModalSelector.modal("show");

        const reportModalBody = await this.garudaApi.getReportTypeDetails(this.tool, this.subtool);
        this.reportModalBody.html(reportModalBody);

        this.initReportContent();
        this.spinnerController.hideSpinner();
    }

    private async initializeGroupContent() {
        this.modalTitleSelector.text(Localization.getText("PersonalProfile_CreateGroupReport"));
        this.spinnerController.showSpinner();

        this.reportModalSelector.modal("show");

        const reportModalBody = await this.garudaApi.getGroupReportContent(this.tool);
        this.reportModalBody.html(reportModalBody);

        this.initReportContent();
        this.spinnerController.hideSpinner();
    }

    private initializeReportTypes() {
        const $this = this;

        $(".report-type-item").on("click", async function () {
            $this.modalResetSelector.children("svg").show();
            $this.modalResetSelector.off("click").on("click", async () => {
                $this.modalTitleSelector.text(Localization.getText("PersonalProfile_Report_Select_Type"));
                $this.generateReportStatus.hide();
                $this.generateReportComplete.hide();
                await $this.initializeContent($this.selectedSubToolEnum);
                $this.modalResetSelector.children("svg").hide();
                $this.reportHeaderInfoIconSelector.hide();
            });

            $this.subtoolName = $(this).data("subtool-name");
            $this.selectedToolEnum = $(this).data("tool-id") as ToolEnum;
            $this.selectedReportType = $(this).data("report-type") as ReportTypeEnum;
            $this.selectedSubToolEnum = $(this).data("subtool-id") as SubToolEnum;

            await $this.loadReportType();
        });
    }

    private async loadReportType() {

        if (this.selectedReportType === ReportTypeEnum.OnboardingLeader || this.selectedReportType === ReportTypeEnum.OnboardingPerson) {
            this.reportHeaderInfoIconSelector.show();
        }

        await this.showReportDetails(this.profileIds, this.selectedToolEnum, this.selectedSubToolEnum, this.selectedReportType, this.subtoolName);
    }

    private async reloadReportWithVariant() {
        const modalTitle = this.plurality === PluralityEnum.Multiple ? Localization.getText("PersonalProfile_IndividualReports") : this.subtoolName;
        await this.showReportDetails(this.profileIds, this.selectedToolEnum, this.selectedSubToolEnum, this.selectedReportType, modalTitle, true);
    }

    private async showReportDetails(profileIds: string[], selectedToolEnum: ToolEnum, selectedSubToolEnum?: SubToolEnum, reportType?: ReportTypeEnum, reportName?: string, noSpinner?: boolean) {
        this.modalTitleSelector.text(reportName ?? "");
        if(!noSpinner) {
            this.spinnerController.showSpinner();
        }

        const isVersusReport = selectedSubToolEnum === SubToolEnum.JobVsPersonal
            || selectedSubToolEnum === SubToolEnum.FocusJobVsPersonal
            || selectedSubToolEnum === SubToolEnum.FocusMirrorVsPersonal
            || selectedSubToolEnum === SubToolEnum.FocusDreamPositionVsPersonal
            || selectedSubToolEnum === SubToolEnum.MirrorVsPersonal
            || selectedSubToolEnum === SubToolEnum.ReferenceVsPersonal
            || selectedSubToolEnum === SubToolEnum.PersonalVsPersonal
            || selectedSubToolEnum === SubToolEnum.FocusPersonalVsPersonal
            || selectedSubToolEnum === SubToolEnum.JobVsJob;

        this.isGroup = selectedSubToolEnum === SubToolEnum.FocusGroup

        const variantTemplate = this.getReportTemplateVariant();
        const language = this.getChosenLanguage();
        let reportDetailsHtml: string;
        if(reportType) {
            if (reportType === ReportTypeEnum.OnboardingFocusPerson.toString()) {
                reportDetailsHtml = await this.garudaApi.getSpecialReportTypeDetailsWithoutGraphs(selectedToolEnum, selectedSubToolEnum, reportType);
            }
            else {
                reportDetailsHtml = await this.garudaApi.getSpecialReportTypeDetails(profileIds[0], reportType);
            }
        } else {
            if (isVersusReport) {
                const vsSubToolEnum: SubToolEnum = ReportModule.getVsSubToolEnum(selectedSubToolEnum);
                reportDetailsHtml = await this.garudaApi.getReportTypeDetails(selectedToolEnum, selectedSubToolEnum, vsSubToolEnum, variantTemplate, language);
            } else if (this.isGroup) {
                const vsSubToolEnum: SubToolEnum = ReportModule.getVsSubToolEnum(SubToolEnum.PersonalVsPersonal);
                reportDetailsHtml = await this.garudaApi.getReportTypeDetails(selectedToolEnum, selectedSubToolEnum, vsSubToolEnum, variantTemplate, language,null,this.isGroup);
            } else if(profileIds.length == 1 && selectedSubToolEnum != SubToolEnum.Notes){
                reportDetailsHtml = await this.garudaApi.getReportTypeDetails(selectedToolEnum, selectedSubToolEnum, null, variantTemplate,language, profileIds[0]);
            } else {
                reportDetailsHtml = await this.garudaApi.getReportTypeDetails(selectedToolEnum, selectedSubToolEnum, null, variantTemplate, language);
            }
        }

        if(!noSpinner) {
            this.spinnerController.hideSpinner();
        }

        this.reportModalBody.html(reportDetailsHtml);

        this.initReportContent(Boolean(reportType), isVersusReport);
    }

    private static getVsSubToolEnum(selectedSubToolEnum: SubToolEnum): SubToolEnum
    {
        switch(selectedSubToolEnum)
        {
            case SubToolEnum.PersonalVsPersonal:
                return SubToolEnum.Person;
            case SubToolEnum.JobVsPersonal:
                return SubToolEnum.Job;
            case SubToolEnum.FocusJobVsPersonal:
                return SubToolEnum.FocusJob;
            case SubToolEnum.FocusMirrorVsPersonal:
                return SubToolEnum.FocusMirror;
            case SubToolEnum.FocusDreamPositionVsPersonal:
                return SubToolEnum.FocusDreamPosition;
            case SubToolEnum.MirrorVsPersonal:
                return SubToolEnum.Mirror;
            case SubToolEnum.ReferenceVsPersonal:
                return SubToolEnum.Reference;
            case SubToolEnum.FocusPersonalVsPersonal:
                return SubToolEnum.FocusPerson;
        }
    }

    private initReportContent(isSpecialReport?: boolean, isVersusReport?: boolean) {
        const $this = this;

        new DropdownModule().init(this.reportLanguageSelector, {
            allowClear: false,
            searchable: false,
        });

        if($(this.reportVariantSelector).length !== 0) {
            new DropdownModule().init(this.reportVariantSelector, {
                allowClear: false,
                searchable: false,
            });

            $(this.reportVariantSelector).on("change", async (e: JQuery.ChangeEvent) => {
                $this.selectedToolEnum = $(e.currentTarget).data("tool-id") as ToolEnum;
                $this.selectedSubToolEnum = $(e.currentTarget).data("subtool-id") as SubToolEnum;
                await $this.reloadReportWithVariant();
            })
        }

        if(isSpecialReport){
            this.bindGeneralSwitch();
            this.bindGeneralFieldSwitches();
            this.bindFieldSwitches();
        } else {
            this.bindSwitches();
        }

        $(".report-modal-dropdown-option").off("click").on("click", async (e) => {
            const reportType = $(e.currentTarget).closest("div.report-modal-footer").find(".report-type").data("report-type");
            const variant = $(e.currentTarget).data("setting-key");
            const saveSettings = this.getSaveSettings(e);
            const reportTemplateVariant = this.getReportTemplateVariant();
            await $this.requestReport(saveSettings, variant, reportType, false, reportTemplateVariant, this.subtool == SubToolEnum.Notes, this.subtool == SubToolEnum.FocusGroup );
        });

        $("#modal-btn-report-request").off("click").on("click", async (e) => {
            const reportType = $('.report-type').data('report-type');
            const saveSettings = this.getSaveSettings(e);
            const reportTemplateVariant = this.getReportTemplateVariant();
            if (reportType)
            {
                await $this.requestReport(saveSettings, null, reportType, isVersusReport, reportTemplateVariant, this.subtool == SubToolEnum.Notes,this.subtool == SubToolEnum.FocusGroup);
            }
            else {
                await $this.requestReport(saveSettings, null, null, isVersusReport, reportTemplateVariant, this.subtool == SubToolEnum.Notes,this.subtool == SubToolEnum.FocusGroup);
            }
        });
    }

    private bindGeneralSwitch = () => {
        $(".generel-switch").off("click").on("click", (e) =>  {
            const isChecked = $(e.currentTarget).prop("checked");
            $(e.currentTarget).closest(".field-group-container").find(".generel-field-switch").prop("checked", isChecked).trigger("change");
        });
    }

    private bindGeneralFieldSwitches = () => {
        $(".generel-field-switch").off("click").on("click", (e => {
            const isChecked = $(e.currentTarget).prop("checked");

            if(isChecked) {
                this.toggleGeneralSwitch(e.currentTarget);
            } else {
                $(e.currentTarget).parents(".field-group-container").find(".generel-switch").prop("checked", false);
            }
        }));

        const generalSwitches = $(".generel-switch");
        generalSwitches.each((i, e) => this.toggleGeneralSwitch(e))
    }

    private toggleGeneralSwitch = (element: HTMLElement) => {
        const parentContainer = $(element).closest(".field-group-container");
        const areAllChecked = parentContainer.find(".generel-field-switch").not(':checked').length === 0;
        if(areAllChecked){
            $(element).closest(".field-group-container").find(".generel-switch").prop("checked", true);
        }
    }

    private bindFieldSwitches = () => {
        $(".field-switch").on("change", (e) => {
            const isChecked = $(e.currentTarget).prop("checked");
            if(isChecked){
                $(e.currentTarget).parents(".field-container").find('label[for='+  e.currentTarget.id  +']').addClass("selected");
            } else {
                $(e.currentTarget).parents(".field-container").find('label[for='+  e.currentTarget.id  +']').removeClass("selected");
            }
        });
    }

    private bindSwitches = () => {
        function disableChildCheckboxes(actuallyChecked: boolean, $childCheckboxes: JQuery<HTMLElementTagNameMap[keyof HTMLElementTagNameMap]>) {
            if(!actuallyChecked) {
                $childCheckboxes.each(function() {
                    $(this).prop('disabled', true);
                });
            }else {
                $childCheckboxes.each(function() {
                    $(this).prop('disabled', false);
                });
            }
        }

        $(".parent-item").off("click").on("click", async function () {
            const $parentCheckbox = $(this);
            const id = `#${$(this).attr("for")}`;
            const counter = `${$(this).attr("data-counter")}`;
            const actuallyChecked = !$(id).is(":checked");
            const actuallyDisabled = $(id).is(":disabled");
            if(actuallyDisabled) return;


            var $childCheckboxes = $parentCheckbox.closest(".report-checkbox-container")
                .find("input[type=checkbox]")
                .not($parentCheckbox);

            $childCheckboxes = $childCheckboxes.slice(1) //We do not wan't the parent checkbox it self
            $childCheckboxes.prop("checked", actuallyChecked);
            disableChildCheckboxes(actuallyChecked,$childCheckboxes)
        });
    }

    private getSaveSettings = (e: JQuery.ClickEvent) => $(e.currentTarget).closest("div.report-modal-footer").find(".checkbox-save-settings").is(":checked");
    private getReportTemplateVariant = (): string | null => {
        const templateVariant = $(this.reportVariantSelector).val() as string;
        return templateVariant ? templateVariant : null;
    };

    private getChosenLanguage = (): string | null => {
        const language = $(this.reportLanguageSelector).val() as string;
        return language ? language : null;
    };


    private  getValueByKey<T>(array: any, key: string) {
        for (var i = 0; i < array.length; i++) {
            if (array[i].key === key) {
                return array[i].value as T;
            }
        }
        // If key is not found, you may want to handle this case accordingly
        return undefined;
    }

    private async requestReport(saveSettings: boolean, variant?: string, reportType?: string, isVersusReport?: boolean, reportTemplateVariant?: string, forNotes: boolean = false, forGroup: boolean = false) {
        const $this = this;

        const reportLanguage: string = $($this.reportLanguageSelector).val() as string;

        const plurality = this.isGroup ? PluralityEnum.Group : this.plurality;

        const settings: object[] = [];

        let profileIds: string[] = [];
        // @ts-ignore
        const subTool: SubToolEnum | undefined = SubToolEnum[$("#SubToolEnumVs").val() as string];
        if (subTool !== undefined){
            profileIds = profileIds.concat(this.getProfileIdsFromStorage(subTool));
        }

        if(reportType && reportType !== ReportTypeEnum.OnboardingFocusPerson.toString()){
            $(".field-switch").each(function () {
                const containerType = $(this).closest(".field-group-container").data("report-type");
                if (!containerType || containerType === reportType){
                    const value = $(this).prop("checked");
                    const key = $(this).data("item-key");

                    settings.push({
                        key: key,
                        value: value
                    });
                }
            });
        } else {
            $(".checkbox-item").each(function () {
                const containerType = $(this).closest(".report-checkbox-container").data("report-type");
                if (!containerType || containerType === reportType){
                    const value = $(`#${$(this).attr("for")}`).is(":checked");
                    const key = $(this).data("item-key");

                    settings.push({
                        key: key,
                        value: value
                    });
                }
            });
        }

        const groupNameKey = $("#group-name-key").data("key-constant");
        let reportName: string;
        $(".textbox-item").each(function () {
            const value = $(`#${$(this).attr("for")}`).val() as string;
            const key = $(this).data("item-key");

            reportName = reportName === undefined ? key === groupNameKey ? value : undefined : reportName;
        });


        const reportResponse = await this.garudaApi.requestReport([...this.profileIds, ...profileIds], reportLanguage, plurality, settings,
            saveSettings, reportName, isVersusReport, variant, reportType, reportTemplateVariant, forNotes, forGroup).then(data =>
        {

            this.reportModalBody.hide();
            this.generateReportStatus.show();

            this.reportModalSelector.on('hide.bs.modal', function (e) {
                    clearInterval(downloadCheck);
                    $this.generateReportStatus.hide();
                    $this.generateReportComplete.hide();
                });

            const reportIsAnonymous = this.getValueByKey<boolean>(settings,anonymous_setting_select_keyword);



                const downloadCheck = setInterval(async () => {
                    const statusResponse = await this.garudaApi.reportStatus([data.reportId]);

                    if (statusResponse[0].status === "Success") {

                        clearInterval(downloadCheck);

                        if(this.generateReportStatus.is(':visible')) // Only show the "Report finished" modal if the user is seeing the "Waiting modal"
                        {
                            const fileUrl = this.garudaApi.getDownloadReportUrl(statusResponse[0].id, data.mimeType);

                            this.generateReportStatus.hide();
                            this.generateReportComplete.show();
                            this.modalTitleSelector.text(Localization.getText("Global_DownloadReport"));
                            $this.modalResetSelector.children("svg").show();

                            const reportMimeTypeText = MimeTypeEnum[data.mimeType].toUpperCase();
                            const reportText = Localization.getText("Global_Report") as string;
                            const reportTypeText = `${reportText} (${reportMimeTypeText})`;
                            $(this.generatedReportTypeSelector).text(reportTypeText);

                            let generatedReportName: string;

                            switch (this.plurality) {
                                case PluralityEnum.Single:
                                    generatedReportName = this.respondentName;
                                    break;
                                case PluralityEnum.Multiple:
                                    generatedReportName = Localization.getText("PersonalProfile_IndividualReports");
                                    break;
                                case PluralityEnum.Group:
                                    generatedReportName = reportName;
                                    break;
                                default:
                                    generatedReportName = undefined;
                            }

                            if(reportIsAnonymous)
                                generatedReportName = Localization.getText("GENERAL_ANONYMOUSE");

                            $(this.generatedReportNameSelector).text(generatedReportName);

                            $(this.reportActionSelector).off("click").on("click", async () => {

                                await this.fileDownloadModule.OpenReport(fileUrl, data.mimeType);

                                this.generateReportComplete.hide();
                                this.reportModalSelector.modal('hide');
                            });
                        }
                    }

                }, 2000);
        },error => {
            this.generateReportComplete.hide();
            this.reportModalSelector.modal('hide');
            } );
    }
}
